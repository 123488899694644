import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import logo from "../../assets/images/blue_logo.svg";
import "./banner.css";

export default class Banner extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				{this.props.banner && (
					<section class="hero is-info">
						<div class="hero-body">
							<div class="container">
								<h1 class="title">Send money to Africa!</h1>
								<h2 class="subtitle">
									<a href="/landingPage" className="button is-primary">
										Click here to find out how!
									</a>
								</h2>
							</div>
						</div>
					</section>
				)}
			</>
		);
	}
}
