import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";

import "./assets/css/bulma.css";
import "./assets/css/core_green.css";
import "./assets/css/icons.css";

// find a way to add icons
import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const el = document.getElementById("root");
if (el === null) throw new Error("Root container missing in index.html");
const root = ReactDOM.createRoot(el);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
