import { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import host from '../../config';
import cogoToast from 'cogo-toast';
import Topnav from '../reusable/Topnav';
import joi from 'joi';
import { joiPasswordExtendCore } from 'joi-password';

import './login.css';
const joiPassword = joi.extend(joiPasswordExtendCore);

const URL = host + '/authManagement';

const validatePassword = (data) => {
  return joi
    .object({
      password: joiPassword
        .string()
        .min(8)
        .minOfSpecialCharacters(1)
        .minOfLowercase(1)
        .minOfUppercase(1)
        .minOfNumeric(1)
        .noWhiteSpaces(),
    })
    .validate(data, { abortEarly: false });
};

const deparam = (function (d, x, params, pair, i) {
  return function (qs) {
    // start bucket; can't cheat by setting it in scope declaration or it overwrites
    params = {};
    // remove preceding non-querystring, correct spaces, and split
    qs = qs
      .substring(qs.indexOf('?') + 1)
      .replace(x, ' ')
      .split('&');
    // march and parse
    for (i = qs.length; i > 0; ) {
      pair = qs[--i].split('=');
      params[d(pair[0])] = d(pair[1]);
    }

    return params;
  }; //--  fn  deparam
})(decodeURIComponent, /\+/g);

function validate(email, password) {
  // true means invalid, so our conditions got reversed
  let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return {
    email: email.length === 0 || email.length < 7,
    password: !regex.test(password),
  };
}

function InputSuccessOrError(props) {
  const shouldMarkError = props.shouldMarkError;

  if (shouldMarkError) {
    return (
      <div className="validation-info error cust-validation-error">
        <span>{props.message}</span>
      </div>
    );
  } else {
    return <span />;
  }
}

class Recovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirm: '',
      passResetToken: '',
      showResetPass: false,
      showResendEmailForm: false,
      showSecurity: false,
      touched: {
        email: false,
      },
      noerorrpage: true,
    };
    this.props.noerorrpage(this.state.noerorrpage);
  }

  handleBlur = (field) => (event) => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  handleFormChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === 'password' || name === 'confirm') {
      this.setState({
        [name]: value,
      });
    } else {
      this.setState({
        [name]: value.toLowerCase(),
      });
    }
  };

  resendEmailVerification = () => {
    const email = this.props.user ? this.props.user.email : this.state.email;

    const data = {
      action: 'resendVerifySignup',
      value: { email: email },
    };

    const closeLoader = cogoToast.loading(`Re-sending email link`, {
      position: 'top-right',
      hideAfter: 0,
    });

    axios
      .post(URL, data)
      .then((response) => {
        //console.log("result from verifying", response.data);

        closeLoader();
        setTimeout(() => {
          cogoToast.success(
            `Email verification link sent to ${this.state.email}`,
            { position: 'top-right' }
          );
        }, 500);
      })
      .catch((error) => {
        //console.log("error sending password reset link", JSON.stringify(error.response));

        closeLoader();
        setTimeout(() => {
          cogoToast.error(
            `Sorry, an error occured while attepting to send link, ${
              error.response.data ? error.response.data.message : ''
            }`,
            { position: 'top-right' }
          );
        }, 500);
      });
  };


  
  secureUsersAccount = (token) => {
    const data = {
      action: 'secureUser',
      token,
    };
    const { hide } = cogoToast.loading(`securing account`, {
      position: 'top-right',
      hideAfter: 0,
    });
    axios
      .post(URL, data)
      .then((response) => {
        hide();
        setTimeout(() => {
          cogoToast.success(`Your Account is secured and placed under review, kindly contact customer support`, {
            position: 'top-right',
          });
        }, 500);
      })
      .catch((error) => {
        hide();
        cogoToast.error(
          `Sorry, an error occurred, kindly login to your account and reset password or contact customer support`,
          { position: 'top-right' }
        );
      });
  };


  confirmResetPassword = (event) => {
    event.preventDefault();

    const { hide } = cogoToast.loading(`Resetting your account password`, {
      position: 'top-right',
      hideAfter: 0,
    });

    const data = {
      action: 'resetPwdLong',
      value: {
        token: this.state.passResetToken,
        password: this.state.password,
      },
    };

    axios
      .post(URL, data)
      .then((response) => {
        hide();

        cogoToast.success(`Your account password has been reset successfully`, {
          position: 'top-right',
        });
        setTimeout(() => {
          window.open('https://www.afriexapp.com/', '_self');
        }, 1500);
      })
      .catch((error) => {
        hide();
        setTimeout(() => {
          cogoToast.error(
            `Sorry, an error occured while attepting to reset account password, ${
              error.response.data ? error.response.data.message : ''
            }`,
            { position: 'top-right' }
          );
        }, 500);
      });
  };

  componentDidMount() {
    const query = this.props.history.location.search;
    const queryData = deparam(query);

    if (queryData['type'] === 'reset') {
      this.setState({
        passResetToken: queryData['token'],
        showResetPass: true,
      });
    }

    if (queryData['type'] === 'security') {
      this.setState({
        showSecurity: true,
      });
      this.secureUsersAccount(queryData['token']);

    }

    if (queryData['resend']) {
      this.setState({
        showResendEmailForm: true,
      });
    }
  }

  render() {
    const { email, password } = this.state;
    const errors = validate(email, password);
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    const shouldMarkError = (field) => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

    const disableBtn = () => {
      const data = {
        password,
      };

      const { error } = validatePassword(data);

      const disable =
       !error && this.state.password === this.state.confirm ? false : true;

      return disable;
    };

    return (
      <div>
        <div class="hero is-white is-fullheight">
          <Topnav />
          <div>
            <div className="form-wrap">
              <section className="form">
                {this.state.showResetPass ? (
                  <>
                    <h4 className="page-formtitle">Reset password</h4>

                    <form onSubmit={this.confirmResetPassword}>
                      <p className="page-formsubtitle">
                        Please type in your new password.
                      </p>
                      <div>
                        <input
                          className="input reset-input cust-input"
                          placeholder="New Password"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onBlur={this.handleBlur('password')}
                          onChange={this.handleFormChange}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>

                      <div>
                        <input
                          className="input reset-input cust-input"
                          placeholder="Confirm Password"
                          type="password"
                          name="confirm"
                          value={this.state.confirm}
                          onBlur={this.handleBlur('confirm')}
                          onChange={this.handleFormChange}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                      <section className="password-requirements">
                        <h3 className="requirements-header">Password must:</h3>
                        <p className='requirement'  style={{ color: this.state.password.length >=8 ? '#009900' : '#000' }}>
                          Contain at least 8 characters
                        </p>
                        <p className="requirements"   style={{ color: this.state.password == this.state.confirm && this.state.password.length > 0 ? '#009900' : '#000' }}>
                          Match with your confirm password
                        </p>
                        <p className="requirements"  style={{ color: /[A-Z]/.test(this.state.password) ? '#009900' : '#000' }}>
                          Contain one uppercase
                        </p>
                        <p className="requirements"  style={{ color:  /[a-z]/.test(this.state.password)  ? '#009900' : '#000' }}>
                        Contain one lowercase alphabet
                        </p>
                        <p className="requirements"  style={{ color:  /.*\d/.test(this.state.password)  ? '#009900' : '#000' }}>
                          Contain at least 1 number
                        </p>
                        <p className="requirements" style={{ color:  /.*\W/.test(this.state.password)  ? '#009900' : '#000' }}>
                        Contain one special character
                        </p>
                      </section>

                      <div>
                        <button
                          className="button reset-btn"
                          disabled={disableBtn()}>
                          <span>Reset Password</span>
                        </button>
                      </div>

                      <section className="form-down">
                        <span>
                          Trouble reseting password?{' '}
                          <a
                            className="support"
                            href="https://www.afriexapp.com/support"
                            target={'_blank'}
                            style={{ color: '#0075FF' }}
                            rel="noreferrer">
                            Contact Support
                          </a>
                        </span>
                      </section>
                    </form>
                  </>
                ) : (
                  <>
                    <h4 className="page-subtitle page-formtitle">
                      {this.state.showResendEmailForm
                        ? "Didn't recieve and email?"
                        : this.state.showSecurity ? 'Account secured' :'Forgot your password?'}
                    </h4>
                    <h5 className="page-formsubtitle">
                      {this.state.showResendEmailForm
                        ? this.props.user
                          ? `Resend email verification link to ${this.props.user.email}`
                          : 'Enter your email we will resend you your email verification link.'
                        : this.state.showSecurity ? 'your account is secured and placed under review kindly contact customer care to help enable it' :"Request password reset via the Afriex Mobile App"}
                    </h5>
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Recovery);
