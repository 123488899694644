export const errorMessages = {
	ERROR_NAME: "Please enter your full legal name",
	ERROR_EMAIL: "Enter a valid email, e.g john@email.com",
	ERROR_PASSWORD: [
		"Must Contain 8 Characters",
		"One Uppercase",
		"One Lowercase",
		"One Number",
		"One special case Character",
	],
	ERROR_PHONE: "Please enter your phone number",
};
