import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavItems extends Component {
	render() {
		return (
			<div className="navbar-menu">
				<div className="navbar-end">
					<div className="navbar-item">
						<Link to="register" className="button new-nav-btn">
							<span> Sign Up </span>
						</Link>
					</div>
					{/* <div className="navbar-end">
							<div className="navbar-item">
								<a className="navbar-item" onClick={this.logout}>
									<span className="has-text-light material-icons">
										power_settings_new
									</span>
									<span
										className="has-text-light"
										style={{ display: "inline-block", marginLeft: "10px" }}
									>
										Sign Out
									</span>
								</a>
							</div>
						</div> */}
				</div>
			</div>
		);
	}
}

export default NavItems;
