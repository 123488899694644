import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import cogoToast from "cogo-toast";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { StaticContext } from "react-router";

import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import {
	deriveUserName,
	parseName,
	validate,
	validateEmail,
	validatePassword,
	InputSuccessOrError,
	validatePhoneNumber,
} from "./utils";
import "./register.css";
import { getAllSupportedCountriesPhone } from "../../utils/countries/supported_countries";
import { errorMessages } from "./messages";
import { FormValues, UserType } from "./types";
import { AFRIEX_UNIVERSAL_LINK, IP_API_ENDPOINT } from "../../constants";
import { CountryCode } from "libphonenumber-js";
import Topnav from "../reusable/Topnav";
import { Checkbox } from "antd";
import host from "../../config";

const { ERROR_EMAIL, ERROR_NAME, ERROR_PASSWORD, ERROR_PHONE } = errorMessages;

const Image = ({ src, alt, ...rest }: any) => (
	<picture {...rest}>
		<source type="image/webp" srcSet={`/static/assets/images/${src}.webp`} />
		<img src={`/static/assets/images/${src}.png`} alt={alt} {...rest} />
	</picture>
);

type LocationState = {
	email: string;
};

const Register = (
	props: RouteComponentProps<{}, StaticContext, LocationState>
): ReactElement => {
	const options = getAllSupportedCountriesPhone().map((country: any) => ({
		label: country.name,
		value: country.iso2,
		dialCode: country.dialCode,
	}));

	const nameRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const emailRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const passwordRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const [isRedirecting, setIsRedirecting] = useState(false);
	const [isTouched, setIsTouched] = useState<{
		[key: string]: boolean;
	}>({});
	const [selectedCountry, setSelectedCountry] = useState<string>("us");
	const [countryCode, setCountryCode] = useState<string>("us");
	const [phone, setPhone] = useState<string>("");
	const [ip, setIp] = useState<string>("");
	const [privacySelected, setPrivacySelected] = useState<boolean>(false);
	const [recaptchaComplete, setRecaptchaComplete] = useState<boolean>(false);

	const referrer = window.location?.pathname?.split("/")[2]?.trim();
	function generateUUID() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function (c) {
				var r = (Math.random() * 16) | 0,
					v = c === "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	}

	useEffect(() => {
		fetch(IP_API_ENDPOINT)
			.then((res: any) => res.json())
			.then((res: any) => {
				const { countryCode, query } = res;
				setCountryCode(countryCode?.toLowerCase() ?? countryCode);
				setIp(query);
			})
			.catch((data: any) => {
				console.log("Request failed:", data);
			});
		if (props?.location?.state) {
			emailRef.current.value = props.location.state.email;
		}
	});

	const handlePhoneChange = (val: string, country: CountryData) => {
		setSelectedCountry(country?.countryCode ?? countryCode);
		setPhone(val);
	};

	const handleBlur = (field: string): void => {
		setIsTouched((state) => ({ ...state, [field]: true }));
	};

	const extractValues = (): Omit<FormValues, "phone"> => {
		const email = emailRef?.current?.value ?? "";
		const name = nameRef?.current?.value ?? "";
		const password = passwordRef?.current?.value ?? "";
		return { email, name, password };
	};

	const shouldMarkError = (
		field: string,
		errors: {
			[key: string]: boolean;
		}
	): boolean => {
		const hasError = errors[field];
		const shouldShow = isTouched[field];
		return hasError ? shouldShow : false;
	};

	const handleRegister = () => {
		let { hide } = cogoToast.loading("Registering your account", {
			position: "top-right",
			hideAfter: 0,
		});

		const { email, name, password } = extractValues();
		const { firstName, lastName } = parseName(name);

		if (!validateEmail(email)) {
			if (hide) {
				hide();
			}

			setTimeout(() => {
				cogoToast.error(`Your email address is not in a valid format`, {
					position: "top-right",
				});
			}, 500);

			return;
		}

		if (
			!validatePhoneNumber(phone, selectedCountry?.toUpperCase() as CountryCode)
		) {
			if (hide) {
				hide();
			}

			setTimeout(() => {
				cogoToast.error(
					`Your phone number is not a valid phone number for the selected country`,
					{
						position: "top-right",
					}
				);
			}, 500);

			return;
		}

		if (!validatePassword(password)) {
			if (hide) {
				hide();
			}
			setTimeout(() => {
				cogoToast.error(
					`Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character`,
					{
						position: "top-right",
					}
				);
			}, 500);
		}
		const userName = deriveUserName(email);
		const deviceType =
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
				navigator.userAgent
			)
				? "Mobile"
				: "Desktop";

		const data = {
			fullName: `${firstName} ${lastName}`,
			firstName: firstName,
			lastName: lastName,
			email: email.toLowerCase(),
			password: password.trim(),
			phone: "+" + phone,
			deviceInfo: {
				deviceId: generateUUID(),
				deviceIp: ip,
				deviceToken: "",
				deviceName: navigator.userAgent,
				deviceCountry: selectedCountry ?? countryCode,
				deviceType: "ios",
				isDeactivated: false,
				isDefault: true,
			},
			referrer: referrer,
			country: selectedCountry ?? countryCode,
		};
		axios
			.post(host + "/v2/users/signup", data)
			.then(() => {
				if (hide) {
					hide();
				}
				setIsRedirecting(true);
			})
			.catch((error: any) => {
				if (error.code === 11000) {
					if (hide) {
						hide();
					}
					setTimeout(() => {
						cogoToast.error(`Sorry, that phone number is already in use`, {
							position: "top-right",
						});
					}, 500);

					return;
				} else {
					if (hide) {
						hide();
					}

					const message = error?.data?.details ?? "";

					setTimeout(() => {
						cogoToast.error(
							`Sorry, an error occurred while attempting to register: ${message}`,
							{ position: "top-right" }
						);
					}, 500);
				}
			});
	};

	const { email, password, name } = extractValues();
	const errors: {
		[key: string]: boolean;
	} = validate({ email, password, name, phone });
	const isDisabled =
		Object.keys(errors).some((x) => errors[x]) ||
		!privacySelected ||
		!recaptchaComplete;

	const countryCodes = options.map((x: any) => x.value);

	return isRedirecting ? (
		<div className="">
			<Topnav />
			<div className="hero is-white page-preloader">
				<div className="section-one">
					<img
						src="/static/assets/images/checkmark.gif"
						alt="Done"
						width={"500px"}
						className="checkmark-img"
					/>

					<div
						style={{
							textAlign: "center",
							paddingLeft: "10px",
							paddingRight: "10px",
							fontSize: "0.8em",
						}}
					>
						Account successfully created!
					</div>
					<span
						style={{
							fontSize: "0.55em",
							alignItems: "center",
							alignContent: "center",
							textAlign: "center",
							paddingLeft: "10px",
							paddingRight: "10px",
						}}
					>
						Download the Afriex app and login with your password
					</span>
					<div className="button-container">
						<a href={AFRIEX_UNIVERSAL_LINK}>
							<div
								style={{
									position: "relative",
								}}
							>
								<div className="green-button"></div>
								<div className="blue-button">
									<div className="download-text">Download the Afriex App</div>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="">
			<Topnav />

			<div className="reg-form-wrap">
				<section className="form">
					<h4 className="page-formtitle" color="black">
						Create your Afriex Account
					</h4>
					<h5 className="page-formsubtitle">
						Become part of the community of thousands who already use Afriex to
						effortlessly send money overseas within minutes!
					</h5>
					<div>
						<label htmlFor={"Phone"} className="cust-label">
							Phone Number
						</label>

						<PhoneInput
							onlyCountries={countryCodes}
							country={countryCode}
							data-testid={"phoneInput"}
							value={phone}
							onChange={handlePhoneChange}
							inputClass={"phone-input"}
							containerClass={"phone-container "}
							countryCodeEditable={false}
							aria-label={"Phone"}
							aria-required="true"
							onBlur={handleBlur.bind(null, "phone")}
						/>

						<InputSuccessOrError
							data-testid={"errorPhone"}
							shouldMarkError={shouldMarkError("phone", errors)}
							message={ERROR_PHONE}
						/>
					</div>
					<div>
						<label htmlFor={"Full Legal Name"} className="cust-label">
							Full Legal Name
						</label>
						<input
							data-testid={"nameInput"}
							className="input cust-input"
							placeholder="Full Legal Name"
							aria-label={"Full Legal Name"}
							aria-required="true"
							type="text"
							ref={nameRef}
							onBlur={handleBlur.bind(null, "name")}
							style={{ width: "100%" }}
						/>

						<InputSuccessOrError
							data-testid={"errorName"}
							shouldMarkError={shouldMarkError("name", errors)}
							message={ERROR_NAME}
						/>
					</div>
					<div>
						<label>Email</label>
						<input
							data-testid={"emailInput"}
							className="input cust-input"
							placeholder="Enter your email"
							aria-label={"Email"}
							aria-required="true"
							type={"email"}
							ref={emailRef}
							onBlur={handleBlur.bind(null, "email")}
						/>

						<InputSuccessOrError
							data-testid={"errorEmail"}
							shouldMarkError={shouldMarkError("email", errors)}
							message={ERROR_EMAIL}
						/>
					</div>
					<div>
						<label className="cust-label">Password</label>
						<input
							data-testid={"passwordInput"}
							className="input cust-input"
							placeholder="Minimum 8 characters"
							type="password"
							name="password"
							aria-label={"Password"}
							aria-required="true"
							ref={passwordRef}
							onBlur={handleBlur.bind(null, "password")}
						/>

						<InputSuccessOrError
							data-testid={"errorPassword"}
							shouldMarkError={shouldMarkError("password", errors)}
							message={ERROR_PASSWORD}
						/>
					</div>
					<div>
						<label className="cust-label">Referral Code</label>
						<input
							className="input cust-input"
							placeholder="Your Referrer"
							type="text"
							name="referrer"
							value={window.location.pathname.split("/")[2]}
							onBlur={handleBlur.bind(null, "referrer")}
							style={{ width: "100%" }}
						/>

						<InputSuccessOrError
							shouldMarkError={shouldMarkError("referrer", errors)}
							message={"Referral username could not be retrieved"}
						/>
					</div>
					<ReCAPTCHA
						sitekey="6Lc_1ngqAAAAAIch1StUfoa_amc3DtzJGRUh6URT"
						onChange={() => {
							setRecaptchaComplete(true);
						}}
					/>
					<div className="form-footer register-footer">
						<p>
							<Checkbox
								checked={privacySelected}
								onChange={() => {
									setPrivacySelected(!privacySelected);
								}}
							/>{" "}
							I have read and agree to Afriex’s
							<a href="terms"> Terms & Conditions</a> and acknowledge the{" "}
							<a href="terms"> Privacy Policy.</a>
						</p>
					</div>
					<div className="mt-10">
						<button
							data-testid={"signupBtn"}
							disabled={isDisabled}
							className="button reset-btn"
							onClick={handleRegister}
						>
							<span>Sign Up</span>
						</button>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Register;
