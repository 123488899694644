import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Header from "../header";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./landing-page.css";

export default class LandingPage extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidUpdate(prevProps) {}

	render() {
		return (
			<div className="hero is-theme-warning landingCustom">
				<Header banner={false} />
				<div id="main-hero" className="hero-body is-clean">
					<div className="container has-text-centered">
						<div className="columns is-vcentered">
							<div
								className="column is-6 caption-column has-text-left"
								style={{ marginTop: "5%" }}
							>
								<h1
									className="clean-title"
									style={{ fontSize: "40px", lineHeight: "50px" }}
								>
									Send money to and from Africa
								</h1>
								<div
									className="subtitle is-5 no-margin"
									style={{ paddingTop: "5%", paddingBottom: "10%" }}
								>
									<p
										className="hero-title"
										style={{
											paddingTop: "5%",
											fontSize: "17px",
											lineHeigh: "1.7",
										}}
									>
										Afriex allows you to send and receive money from anyone
										around the world. With amazing rates and instant settlement,
										Afriex has you covered. Welcome to the future of money.
									</p>
								</div>

								<div class=" is-5 is-12-mobile">
									<div className=" has-text-centered-mobile">
										<h2
											style={{
												fontSize: "22px",
												fontWeight: "bold",
												paddingBottom: "10px",
											}}
										>
											Download it on:
										</h2>
									</div>

									<p className=" buttons is-hidden-mobile">
										<a
											className=""
											href="https://play.google.com/store/apps/details?id=com.afriex.afriex"
										>
											<button
												className="button is-large"
												style={{ background: "#34A9FF", color: "white" }}
											>
												<span className="icon">
													<i className="fa fa-android"></i>
												</span>
												<span>Android</span>
											</button>
										</a>

										<a
											className=""
											href="https://apps.apple.com/us/app/afriex-send-money-to-africa/id1492022568?ls=1"
										>
											<button
												className="button is-large"
												style={{ background: "#34A9FF", color: "white" }}
											>
												<span className="icon">
													<i className="fa fa-apple"></i>
												</span>
												<span>IPhone</span>
											</button>
										</a>
									</p>
									<p className=" buttons is-centered is-hidden-tablet is-hidden-desktop is-hidden-widescreen">
										<a
											className=""
											href="https://play.google.com/store/apps/details?id=com.afriex.afriex"
										>
											<button
												className="button is-large"
												style={{ background: "#34A9FF", color: "white" }}
											>
												<span className="icon">
													<i className="fa fa-android"></i>
												</span>
												<span>Android</span>
											</button>
										</a>

										<a
											className=""
											href="https://apps.apple.com/us/app/afriex-send-money-to-africa/id1492022568?ls=1"
										>
											<button
												className="button is-large"
												style={{ background: "#34A9FF", color: "white" }}
											>
												<span className="icon">
													<i className="fa fa-apple"></i>
												</span>
												<span>IPhone</span>
											</button>
										</a>
									</p>
								</div>
							</div>

							<div className="mt-80 column is-5 is-offset-1 rectangleImg landing_mobile">
								<div className="columns is-vcentered dottedBoxPhone">
									<img
										id="hero-img"
										className="hero-img"
										src={require("../../assets/images/home-phone.svg")}
									/>
								</div>
							</div>
						</div>
						<div style={{ display: "flex" }}>
							<img
								src={require("../../assets/images/dss-compliant.png")}
								style={{
									width: "100px",
									height: "56px",
									verticalAlign: "middle",
									marginRight: "10px",
								}}
							/>
							<div style={{ alignSelf: "center" }}>
								Afriex is safe, secure and PCI DSS Compliant.
							</div>
						</div>
					</div>
				</div>

				<Features />
				<h2
					className="has-text-centered"
					style={{ fontWeight: 600, fontSize: "50px" }}
				>
					How it Works{" "}
				</h2>

				<section className="section mt-40">
					<div className="container">
						<div className="columns">
							<div className="column is-offset-1 is-10 service-layout-1">
								<h3 style={{ color: "#444f60" }}>1. Deposit Money</h3>

								<p style={{ color: "#444f60" }}>
									You can deposit either USD or Naira. You can choose which
									currency to hold your money in. It’s like having a global bank
									account. In the future we will be adding more currencies.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="columns">
							<div className="column is-offset-1 is-10 service-layout-1">
								<h3 style={{ color: "#444f60" }}>2. Select the receiver</h3>

								<p style={{ color: "#444f60" }}>
									Afriex lets you send money to anyone on your contact list, no
									matter where they live. Just scroll and select who you want to
									send to.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="section ">
					<div className="container">
						<div className="columns">
							<div className="column is-offset-1 is-10 service-layout-1">
								<h3 style={{ color: "#444f60" }}>3. Send </h3>

								<p style={{ color: "#444f60" }}>
									That’s it. Once you confirm the amount and the receiver, the
									money is sent. The receiver will receive a notification and
									can instantly withdraw to their account.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="columns"></div>
					</div>
				</section>

				<MediaSection />

				<Testimonials />
				<Footer banner={false} />
			</div>
		);
	}
}

const Features = () => {
	return (
		<div className="features" style={{ marginTop: "5%", boxShadow: "none" }}>
			<div className="columns">
				<div className="column is-4 feature-container has-text-centered">
					<div style={{ marginBottom: "20px" }}>
						<img
							src={require("../../assets/images/globe-africa.png")}
							style={{ width: "100px" }}
						/>
					</div>
					<div>
						<h3>Borderless Accounts</h3>
						<p>
							A multi-currency account which allows you to store money in any
							currency.
						</p>
					</div>
				</div>

				<div className="column is-4 feature-container has-text-centered">
					<div style={{ marginBottom: "20px" }}>
						<img
							src={require("../../assets/images/heart-eyes.png")}
							style={{ width: "100px" }}
						/>
					</div>
					<div>
						<h3>Affordable Rates</h3>
						<p>
							We give you the lowest rates so that you get the most for your
							money.
						</p>
					</div>
				</div>

				<div className="column is-4 feature-container has-text-centered">
					<div style={{ marginBottom: "20px" }}>
						<img
							src={require("../../assets/images/lock.png")}
							style={{ width: "100px" }}
						/>
					</div>
					<div>
						<h3>Fast and Safe</h3>
						<p>
							Instant deposit, send, and withdrawal secured by blockchain
							technology.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const Footer = () => (
	<footer className="newfooter">
		<div className="container mt-40">
			<div className="level">
				<div className="level-item level-social">
					<a
						href="https://m.facebook.com/Afriex-287784992150320/about"
						className="level-item"
						target="_blank"
					>
						<span className="icon is-large">
							<i className="fa fa-facebook" style={{ fontSize: "2em" }} />
						</span>
					</a>
					<a
						href="https://twitter.com/afriexapp"
						className="level-item"
						target="_blank"
					>
						<span className="icon is-large">
							<i className="fa fa-twitter" style={{ fontSize: "2em" }} />
						</span>
					</a>
					<a
						href="https://www.instagram.com/afriexapp"
						className="level-item"
						target="_blank"
					>
						<span className="icon is-large">
							<i className="fa fa-instagram" style={{ fontSize: "2em" }} />
						</span>
					</a>
					<a
						href="https://blog.afriex.co"
						className="level-item"
						target="_blank"
					>
						<span className="icon is-large">
							<i className="fa fa-rss" style={{ fontSize: "2em" }} />
						</span>
					</a>
					<a href="https://t.me/afriex" className="level-item" target="_blank">
						<span className="icon">
							<i className="fa fa-telegram" style={{ fontSize: "2em" }} />
						</span>
					</a>
				</div>
			</div>
			<div className="level">
				<div className="level-item">
					{/* <span>
            Made with{" "}
            <span className="icon">
              <i className="fa fa-heart color-red" />
            </span>{" "}
            in Africa{" "}
          </span> */}
				</div>
			</div>

			<div className="level">
				<div className="level-item">
					<span className="">© 2020 Afriex. All Rights Reserved </span>
				</div>
			</div>
			{/* <div className="level">
        <div className="level-item">
          <span className="">For support, email admin@afriex.co </span>
        </div>
      </div> */}
			<div className="level bottomLinks">
				<div className="level-item">
					<Link className="level-item footer-text" to="/">
						Home
					</Link>

					<div className="level-item footer-text">/</div>

					<Link className="level-item footer-text" to="about">
						About
					</Link>

					<div className="level-item footer-text">/</div>

					<Link className="level-item footer-text" to="terms">
						Terms
					</Link>

					{/* <div className="level-item footer-text">/</div>

          <Link className="level-item footer-text" to="login">
            Login
          </Link>

          <div className="level-item footer-text">/</div>

          <Link
            className="level-item footer-text"
            to="register"
            
          >
            Sign Up
          </Link> */}
				</div>
			</div>
		</div>
	</footer>
);

const MediaSection = () => (
	<div className="">
		<div className="columns">
			{/* <p>Some Nice Caption</p> */}
			<div className="column has-text-centered videoStyleDiv">
				<div className=" has-text-centered-mobile">
					<h2
						style={{
							fontSize: "22px",
							fontWeight: "bold",
							paddingBottom: "10px",
						}}
					>
						Download it on:
					</h2>
				</div>
				<p className=" buttons is-centered ">
					<a
						className=""
						href="https://play.google.com/store/apps/details?id=com.afriex.afriex"
					>
						<button
							className="button is-large"
							style={{ background: "#34A9FF", color: "white" }}
						>
							<span className="icon">
								<i className="fa fa-android"></i>
							</span>
							<span>Android</span>
						</button>
					</a>

					<a
						className=""
						href="https://apps.apple.com/us/app/afriex-send-money-to-africa/id1492022568?ls=1"
					>
						<button
							className="button is-large"
							style={{ background: "#34A9FF", color: "white" }}
						>
							<span className="icon">
								<i className="fa fa-apple"></i>
							</span>
							<span>Iphone</span>
						</button>
					</a>
				</p>
			</div>
		</div>
	</div>
);

const Testimonials = () => {
	const setting = {
		dots: true,
		infinite: true,
		speed: 50,
		slidesToShow: 2,
		slidesToScroll: 1,
	};
	return (
		<div style={{ marginBottom: "5%" }} className="slider_container">
			<h2
				className="has-text-centered"
				style={{ marginBottom: "5%", fontWeight: 600 }}
			>
				{" "}
				What people say about us
			</h2>
			{/* <Slider {...setting}> */}
			<div className="columns is-variable dottedTestimony">
				<div className="column is-offset-1 is-3 testimonyCard1 mt-20">
					<img
						className="quote-mini-landing"
						src={require("../../assets/images/quote-mini.png")}
					/>
					<p>"Very interesting pls guys keep on trading with Afriex."</p>
					<img
						className="avatar"
						src={require("../../assets/images/juliet-testimonial.jpg")}
						alt=""
					/>
					<span> Juliet E.</span>
				</div>
				<div className="column is-3 testimonyCard2 mt-20">
					<img
						className="quote-mini-landing"
						src={require("../../assets/images/quote-mini-2.png")}
					/>
					<p>
						"It's a great innovation. I like the ease at which we do
						transactions"
					</p>
					<img
						className="avatar"
						src={require("../../assets/images/Ugo-Alaribe.jpeg")}
						alt=""
					/>
					<span>Ugo A.</span>
				</div>
				<div className="column is-3 testimonyCard2 mt-20">
					<img
						className="quote-mini-landing"
						src={require("../../assets/images/quote-mini-2.png")}
					/>
					<p>
						"Fast cash withdrawal, quick responses, thank you, Afriex made us
						very satisfied."
					</p>
					<img
						className="avatar"
						src={require("../../assets/images/audu-testimonial.jpg")}
						alt=""
					/>
					<span>Audu A.</span>
				</div>
			</div>
			{/* </Slider> */}
		</div>
	);
};

const ModalForm = () => {
	return (
		<form
			action="https://unclebanks.us19.list-manage.com/subscribe/post?u=113e39c8ee048972eebdd9cc2&amp;id=e081014b96"
			method="post"
			id="mc-embedded-subscribe-form"
			name="mc-embedded-subscribe-form"
			className="validate"
			target="_blank"
			noValidate
		>
			<div id="mc_embed_signup_scroll">
				<div className="mc-field-group column is-8-desktop is-12-tablet ">
					<input
						type="email"
						name="EMAIL"
						className="input is-large required email"
						id="mce-EMAIL"
						placeholder="Email Address"
					/>
				</div>

				<div id="mce-responses" className="clear">
					<div
						className="response"
						id="mce-error-response"
						style={{ display: "none" }}
					/>
					<div
						className="response"
						id="mce-success-response"
						style={{ display: "none" }}
					/>
				</div>

				<div
					style={{ position: "absolute", left: "-5000px" }}
					aria-hidden="true"
				>
					<input
						type="text"
						name="b_113e39c8ee048972eebdd9cc2_e081014b96"
						tabIndex="-1"
					/>
				</div>

				<div className="column is-4-desktop is-12-tablet">
					<button
						type="submit"
						value="Get Early Acce$$!"
						name="subscribe"
						id="mc-embedded-subscribe"
						className="button is-focused is-large "
						style={{
							backgroundColor: "rgb(42, 178, 70)",
							color: "white",
							border: "none",
						}}
					>
						<span className="is-size-6-desktop">Submit!</span>
					</button>
				</div>
			</div>
		</form>
	);
};
