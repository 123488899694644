import React, { Component } from "react";

import "./page-404.css";
import Header from "../header";

class Page404 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const stylelogo = {
      width: "10px"
    };
    return (
      <div className="Page404">
        <Header />

        <div className="paga404body">
          <p className="para-page-error">This page isn't available</p>
          <br />

          <p>
            The link you followed may be broken, or the page may have been
            removed.
          </p>

          <img
            src="http://res.cloudinary.com/easywaya/image/upload/v1538167691/404_lly1qq.jpg"
            width="500"
          />

          <br />
          <br />
          <br />
          <a href="/">Go back to the previous page </a>
        </div>
      </div>
    );
  }
}

export default Page404;
