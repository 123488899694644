let host: string;

switch (window.location.hostname) {
	case "localhost":
		host = "http://localhost:3030";
		break;

	case "afriex-staging-web.herokuapp.com":
		host = "https://afriex-staging-server.herokuapp.com";
		break;

	default:
		host = "https://prod.afx-server.com";
}

export default host;
