import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import NavItems from "./nav-items";
import Banner from "./banner/banner";
function openNav() {
	document.getElementById("mySidenav").style.width = "434px";
}

class Header extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	handleCloseClick = (e) => {
		// Get all "navbar-burger" elements
		var $navbarBurgers = Array.prototype.slice.call(
			document.querySelectorAll(".navbar-burger"),
			0
		);

		// Check if there are any navbar burgers
		if ($navbarBurgers.length > 0) {
			// Add a click event on each of them
			$navbarBurgers.forEach(function ($el) {
				// Get the target from the "data-target" attribute
				var target = $el.dataset.target;
				var $target = document.getElementById(target);

				// Toggle the class on both the "navbar-burger" and the "navbar-menu"
				$el.classList.toggle("is-active");
				$target.classList.toggle("is-active");
			});
		}
	};

	render() {
		const { loggedIn, loggingIn } = this.props;

		return (
			<>
				<Banner banner={this.props.banner} />
				<nav className="navbar is-dark is-transparent new-bg ">
					<div
						className="container"
						style={{ display: "flex", justifyContent: "space-between" }}
					>
						<div className="navbar-brand">
							{loggedIn && (
								<a
									role="button"
									className="navbar-burger burger"
									onClick={openNav}
									style={{ margin: "10px" }}
								>
									<span aria-hidden="true" />
									<span aria-hidden="true" />
									<span aria-hidden="true" />
								</a>
							)}

							{!loggedIn ? (
								<Link
									to="/"
									className="navbar-item new-brand"
									style={
										this.props.banner === false
											? { color: "#4a4a4a" }
											: { color: "whitesmoke" }
									}
								>
									<img
										src={require("../assets/images/afriex-logo-2.jpeg")}
										style={{ paddingRight: "4%" }}
									/>
									Afriex
								</Link>
							) : (
								<Link
									to="dashboard"
									className="navbar-item"
									style={{ fontSize: "2em" }}
								>
									AFRIEX
								</Link>
							)}

							{/* {!loggedIn && <Link
                to="register"
                className="navbar-item is-hidden-desktop"
              >
                Sign Up
                </Link>
              } */}
						</div>

						{/* {!loggedIn && (
              <Link
                to="login"
                className="navbar-item is-hidden-desktop"
                
              >
                Login
              </Link>
            )} */}

						<NavItems banner={this.props.banner} />
					</div>
				</nav>
			</>
		);
	}
}

export default Header;
